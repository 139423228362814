"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseModel_dto_1 = require("../base/baseModel.dto");
const book_1 = require("../book");
const arc_team_1 = require("../arc-team");
const claim_1 = require("./claim");
const module_constants_1 = require("../../module.constants");
const modelStats_dto_1 = require("./modelStats.dto");
let ArcDto = class ArcDto extends baseModel_dto_1.BaseModelDto {
    constructor() {
        super(...arguments);
        this.arcType = module_constants_1.bsConstants.ARCS.TYPE.EBOOK;
        this.discoverableType = module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.PUBLIC;
        this.participationType = module_constants_1.bsConstants.ARCS.PARTICIPATION_TYPE.PUBLIC;
        this.participationPreventionType = module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.OUTSTANDING;
        this.copyCountType = module_constants_1.bsConstants.ARCS.COPY_COUNT_TYPE.ALL;
        this.seriesLimitType = module_constants_1.bsConstants.ARCS.SERIES_LIMIT_TYPE.ANYONE;
        this.reviewFlags = 0;
        this.optionalReviewFlags = 0;
        this.requiredReviewCount = 1;
        this.optionalReviewCount = 0;
        this.reviewDays = 0;
        this.vipExclusiveAccessDays = 0;
    }
};
__decorate([
    (0, graphql_1.Field)(type => book_1.BookDto, { nullable: true }),
    __metadata("design:type", book_1.BookDto)
], ArcDto.prototype, "book", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcDto.prototype, "bookId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(type => arc_team_1.ArcTeamDto, { nullable: true }),
    __metadata("design:type", arc_team_1.ArcTeamDto)
], ArcDto.prototype, "arcTeam", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcDto.prototype, "arcTeamId", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "specialInformation", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.TYPE.EBOOK }),
    __metadata("design:type", Number)
], ArcDto.prototype, "arcType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.PUBLIC }),
    __metadata("design:type", Number)
], ArcDto.prototype, "discoverableType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.PARTICIPATION_TYPE.PUBLIC }),
    __metadata("design:type", Number)
], ArcDto.prototype, "participationType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.OUTSTANDING }),
    __metadata("design:type", Number)
], ArcDto.prototype, "participationPreventionType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.COPY_COUNT_TYPE.ALL }),
    __metadata("design:type", Number)
], ArcDto.prototype, "copyCountType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcDto.prototype, "copyCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.ARCS.SERIES_LIMIT_TYPE.ANYONE }),
    __metadata("design:type", Number)
], ArcDto.prototype, "seriesLimitType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "reviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "optionalReviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true, defaultValue: 1 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "requiredReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "optionalReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcDto.prototype, "liveDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "reviewDays", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcDto.prototype, "stopDownloadDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => Boolean, { nullable: true }),
    __metadata("design:type", Boolean)
], ArcDto.prototype, "neverEndingPrivate", void 0);
__decorate([
    (0, graphql_1.Field)(t => [claim_1.ArcClaimDto], { nullable: true }),
    __metadata("design:type", Array)
], ArcDto.prototype, "claims", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], ArcDto.prototype, "isDraft", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "usDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "ukDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "authorsDirectDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "koboDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcDto.prototype, "vipExclusiveAccessDays", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcDto.prototype, "dueDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcDto.prototype, "dueCutOffDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => modelStats_dto_1.ArcModelStatsDto, { nullable: true }),
    __metadata("design:type", modelStats_dto_1.ArcModelStatsDto)
], ArcDto.prototype, "stats", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcDto.prototype, "importedId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcDto.prototype, "alertFlags", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "liveDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "stopDownloadDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "dueDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcDto.prototype, "dueCutOffDateStr", void 0);
ArcDto = __decorate([
    (0, graphql_1.ObjectType)()
], ArcDto);
exports.ArcDto = ArcDto;
