"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bsConstants = void 0;
const authorRole = {
    FREE: 'free',
    GROWING: 'growing',
    PRO: 'pro',
    BEST_SELLING: 'bestSelling',
    PUBLISHER: 'publisher'
};
exports.bsConstants = {
    APP: {
        VERSION: '2.8.11',
        SOURCE: {
            REVIEWER: 'reviewerApp',
        },
        HOME_URLS: {
            PUBLISHER: '/review-campaigns',
            REVIEWER: '/review-copies',
            ADMIN: '/dashboard',
            WEBSITE: '/authors'
        },
        HELP_HERO_STOP_HELP: new Date(2022, 3, 7),
        HELP_HERO_MAX_TOURS: 2,
        HELP_HERE_COMPLETE_ON_TOURS: ['TENO5HCzfsS', 'xgfHLNijbxb'],
        LIVE_DATE: new Date(2022, 2, 7), // 7th March
    },
    USER: {
        ROLES: {
            APPLICATION: {
                STATUS: {
                    NONE: 0,
                    APPLIED: 1,
                    DECLINED: 2,
                    APPROVED: 3
                }
            },
            TYPE: {
                AUTHOR: 0,
                REVIEWER: 1,
                ADMIN: 2
            },
            REVIEWER: {
                UNSET: 'unset',
                NEW: 'new',
                VALUED: 'valued',
                TOP: 'top',
                DEV: 'dev'
            },
            AUTHOR: authorRole
        },
        CONSENT_METHOD: {
            NONE: 0,
            PRE_GDPR: 1,
            SIGN_UP_PAGE: 2,
            SOCIAL_POPUP: 3,
            RECONSENT: 4
        },
        EMAILS: {
            STATUS: {
                VERIFIED: 0,
                UNVERIFIED: 1,
                BLOCKED: 2
            }
        },
        PASSWORDS: {
            MIN_LENGTH: 8
        },
        ROLE_SELECTION: {
            READER: 1,
            AUTHOR: 2,
            PUBLISHER: 3,
            VA: {
                READER: 4,
                AUTHOR: 5
            }
        },
        REVIEWER_APPLICATION: {
            STATUS: {
                NONE: 0,
                APPLIED: 1,
                DECLINED: 2,
                REDACTED: 3,
                APPROVED: 4
            }
        },
        USER_STATUS: {
            NONE: 0,
            PREVENT_ARC_DOWNLOADS: 1,
            SHADOW_BAN: 2,
            LOCKED: 3,
            NEW_AND_UNRELEASED_BAN: 4
        },
        USER_STATUS_KEYS: {
            [0]: 'none',
            [1]: 'preventArcDownloads',
            [2]: 'shadowBan',
            [3]: 'locked',
            [4]: 'newAndUnreleasedBan' // author
        },
        REPORT_TYPE: {
            NONE: 0,
            REVIEW: 1,
            CANCELLATION: 2,
            MESSAGE: 3,
            GENERIC: 4
        },
        REPORT_TYPE_KEYS: {
            [0]: 'none',
            [1]: 'review',
            [2]: 'cancellation',
            [3]: 'message',
            [4]: 'generic'
        },
        BREVO_LISTS: {
            NONE: 0,
            ALL: 1,
            LIMITED: 2
        }
    },
    ARC_TEAMS: {
        AUTO_ACTION: {
            TYPE: {
                APPROVE: 1,
                VIP: 2,
                BLOCK: 3
            }
        },
        MEMBERS: {
            STATUS_LOG: {
                TYPE: {
                    LEFT: 0,
                    CANCELLED: 1,
                    REQUESTED_JOIN: 2
                }
            },
            TYPE: {
                REGULAR: 1,
                VIP: 2,
                NONE: 3 // Not a member
            },
            STATUS: {
                AWAITING: 1,
                ACTIVE: 2,
                BLOCKED: 4,
                REJECTED: 5,
                INVITED: 6,
                ACCEPTED_INVITE: 7,
                NOT_MEMBER: 8
            },
            STATUS_REASON_TYPE: {
                NONE: 0,
                GENERIC: 1,
                LIED: 2,
                CANCELLED: 3,
                NO_INTEREST: 4,
                LOW_RATING: 5,
                HARASSMENT: 6,
                OTHER: 7
            },
            BLOCK_STATUS_REASON_KEYS: {
                [0]: 'generic',
                [1]: 'lied',
                [2]: 'cancelled',
                [3]: 'notInterested',
                [4]: 'lowRating',
                [5]: 'lowRatingHint',
                [6]: 'harassment',
                [7]: 'other'
            }
        },
        DISCLAIMERS: {
            FREE_VIA_BS: 1,
            FREE: 2,
            VIA_ARC: 3
        },
        REVIEW_USER: {
            FILTER: {
                ALL_BS: -1,
                ALL_TEAM: -2
            }
        }
    },
    ARCS: {
        PIRATE: {
            STATE: {
                REPORTED: 0,
                HANDLED: 1
            }
        },
        FILTER_GROUP: {
            NEW_UNRELEASED: 0,
            AUTHOR_TEAM: 1,
            EXCLUSIVE: 2,
            PAST_RELEASES: 3,
            ALL: 4
        },
        STATUS: {
            NONE: 0,
            ACTIVE: 1,
            SCHEDULED: 2,
            FINISHED: 3
        },
        STATUS_KEYS: {
            [0]: 'draft',
            [1]: 'active',
            [2]: 'scheduled',
            [3]: 'finished'
        },
        CLAIMS: {
            GROUP_COUNT: {
                ACTIVE: 'active',
                DUE: 'due',
                DELAYED: 'delayed',
                PENDING: 'pending',
                COMPLETE: 'complete',
                CANCELLED: 'cancelled'
            },
            FILTER_GROUP: {
                NONE: 0,
                ACTIVE: 1,
                DUE: 2,
                DELAYED: 3,
                PENDING_PUBLISH: 4,
                COMPLETE: 5,
                CANCELLED: 6
            },
            INELIGIBLE_REASONS: {
                NONE: 0,
                DOWNLOAD_LOCK: 1,
                TOO_MANY_DUE_REVIEWS: 2,
                ARC_DUE_FOR_TOO_LONG: 3,
                WEEKLY_CLAIM_LIMIT_REACHED: 4,
                TOP_REVIEWERS_ONLY: 5,
                PRIVATE_ARC: 6,
                SERIES_READING_REQUIRED: 7,
                OUTSTANDING_REVIEW_FOR_THIS_TEAM: 8,
                STORE_REQUIREMENTS_NOT_MET: 9,
                ALREADY_REVIEWED: 10,
                NO_COPIES_LEFT: 11,
                EMAIL_NOT_VERIFIED: 12,
                NOT_PAID_ACCOUNT: 13
            },
            INELIGIBLE_REASON_KEYS: {
                [0]: 'none',
                [1]: 'downloadLock',
                [2]: 'tooManyDueReviews',
                [3]: 'arcDueForTooLong',
                [4]: 'weeklyClaimLimitReached',
                [5]: 'topReviewersOnly',
                [6]: 'privateArc',
                [7]: 'seriesReadingRequired',
                [8]: 'outstandingReviewForThisTeam',
                [9]: 'storeRequirementsNotMet',
                [10]: 'alreadyReviewed',
                [11]: 'noCopiesLeft',
                [12]: 'emailNotVerified',
                [13]: 'notPaidAccount'
            },
            STATUS: {
                CLAIMED: 0,
                CANCELLED: 1,
                COMPLETE: 2,
                IN_PROGRESS: 3 // User has added BS review and it's now "in progress",
            },
            REVIEWER_STATUS: {
                ACTIVE: 0,
                DUE: 1,
                DELAYED: 2,
                PENDING_PUBLISHER: 3,
                COMPLETE: 4,
                WITHDRAWN: 5
            },
            STATE: {
                NEW: 0,
                READ: 1,
                NEW_ACTIVITY: 2,
                NONE: 3
            },
            STATUS_KEYS: {
                [0]: 'claimed',
                [1]: 'cancelled',
                [2]: 'complete',
                [3]: 'inProgress'
            },
            CANCELLATION_TYPE: {
                POORLY_EDITED: 0,
                STORY: 1,
                PERSONAL: 2,
                BAD_DESCRIPTION: 3,
                BAD_FIT: 4,
                COULD_NOT_REVIEW: 5,
                OTHER: 6
            },
            CANCELLATION_TYPE_KEYS: {
                [0]: 'poorlyEdited',
                [1]: 'story',
                [2]: 'personal',
                [3]: 'badDescription',
                [4]: 'badFit',
                [5]: 'couldNotReview',
                [6]: 'other',
            },
            DOWNLOAD_TYPE: {
                NONE: 0,
                KINDLE: 1,
                EMAIL: 2,
                DOWNLOAD: 3,
                AUDIO_BOOK: 4,
                READ_HERE: 5
            },
            FILE_TYPE: {
                NONE: 0,
                EPUB: 1,
                PDF: 2
            }
        },
        REVIEWS: {
            FILTER: {
                ALL_BOOKS: -1,
                ALL_PEN_NAMES: -1
            },
            AUDIO_FLAGS: [256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536],
            // don't add authors direct to ALL_FLAGS
            ALL_FLAGS: 65535,
            FLAGS: {
                NONE: 0,
                AMAZON: 1,
                GOOGLE: 2,
                GOOD_READS: 4,
                KOBO: 8,
                BOOK_BUB: 16,
                ITUNES: 32,
                BARNES: 64,
                SMASH_WORDS: 128,
                AUDIBLE_US: 256,
                AUDIBLE_UK: 512,
                AUDIO_BOOK_BUB: 1024,
                AUDIO_KOBO: 2048,
                AUDIO_GOOGLE: 4096,
                AUDIO_ITUNES: 8192,
                AUDIO_BARNES: 16384,
                AUDIO_GOODREADS: 32768,
                AUDIO_AUTHORS_DIRECT: 65536,
            },
            FLAG_BOOK_KEYS: {
                NONE: '',
                AMAZON: 'linkAmazonAsin',
                GOOGLE: 'linkGoogle',
                GOOD_READS: 'linkGoodreads',
                KOBO: 'linkKobo',
                BOOK_BUB: 'linkBookbub',
                ITUNES: 'linkITunes',
                BARNES: 'linkBarnes',
                SMASH_WORDS: 'linkSmashwords',
                AUDIBLE_US: 'linkAudibleUs',
                AUDIBLE_UK: 'linkAudibleUk',
                AUDIO_BOOK_BUB: 'linkBookbubAudio',
                AUDIO_KOBO: 'linkKoboAudio',
                AUDIO_GOOGLE: 'linkGoogleAudio',
                AUDIO_ITUNES: 'linkITunesAudio',
                AUDIO_BARNES: 'linkBarnesAudio',
                AUDIO_GOODREADS: 'linkGoodreadsAudio',
                AUDIO_AUTHORS_DIRECT: 'linkAuthorsDirect' // not used
            },
            FLAG_BOOK_KEYS_SITE: {
                [0]: '',
                [1]: 'linkAmazonAsin',
                [2]: 'linkGoogle',
                [4]: 'linkGoodreads',
                [8]: 'linkKobo',
                [16]: 'linkBookbub',
                [32]: 'linkITunes',
                [64]: 'linkBarnes',
                [128]: 'linkSmashwords',
                [256]: 'linkAudibleUs',
                [512]: 'linkAudibleUk',
                [1024]: 'linkBookbubAudio',
                [2048]: 'linkKoboAudio',
                [4096]: 'linkGoogleAudio',
                [8192]: 'linkITunesAudio',
                [16384]: 'linkBarnesAudio',
                [32768]: 'linkGoodreadsAudio',
                [65536]: 'linkAuthorsDirect' // not used
            },
            FLAG_DESCRIPTIONS: {
                [0]: 'None',
                [1]: 'Amazon',
                [2]: 'Google Play',
                [4]: 'Goodreads',
                [8]: 'Kobo',
                [16]: 'Bookbub',
                [32]: 'Apple Books',
                [64]: 'Barnes & Noble',
                [128]: 'Smashwords',
                [256]: 'Audible US',
                [512]: 'Audible UK',
                [1024]: 'Bookbub (Audio)',
                [2048]: 'Kobo (Audio)',
                [4096]: 'Google (Audio)',
                [8192]: 'Apple Books (Audio)',
                [16384]: 'Barnes & Noble (Audio)',
                [32768]: 'Goodreads (Audio)',
                [65536]: 'Authors Direct' // Audio
            },
            FLAG_ICONS: {
                [0]: '',
                [1]: 'amazon',
                [2]: 'google-play',
                [4]: 'goodreads',
                [8]: 'kobo',
                [16]: 'bookbub',
                [32]: 'itunes',
                [64]: 'barnes',
                [128]: 'smashwords',
                [256]: 'audible',
                [512]: 'audible',
                [1024]: 'bookbub',
                [2048]: 'kobo',
                [4096]: 'google-play',
                [8192]: 'itunes',
                [16384]: 'barnes',
                [32768]: 'goodreads',
                [65536]: 'authors-direct'
            },
            SITE: {
                STATUS: {
                    NONE: 0,
                    COMPLETE: 1,
                    DELAYED: 2,
                    REJECTED: 8
                },
                STATE: {
                    NEW_LINK: 0,
                    NONE: 1,
                    READ: 2
                }
            }
        },
        TYPE: {
            EBOOK: 1,
            AUDIO_BOOK: 2
        },
        DISCOVERABLE_TYPE: {
            PUBLIC: 1,
            TEAM_ONLY: 2
        },
        PARTICIPATION_TYPE: {
            PUBLIC: 1,
            TOP_REVIEWERS: 2,
            ANYONE_WITH_LINK: 3,
            ON_TEAM: 4
        },
        PARTICIPATION_PREVENTION_TYPE: {
            OUTSTANDING: 1,
            NONE: 2
        },
        COPY_COUNT_TYPE: {
            ALL: 1,
            LIMITED: 2
        },
        SERIES_LIMIT_TYPE: {
            ANYONE: 1,
            PREVIOUS_REVIEWERS: 2
        },
        NEVER_ENDING_PRIVATE_TYPE: {
            NEVER_ENDING: 1,
            LIMITED: 2
        },
        YOUR_ARCS: {
            SORTING: {
                DUE_DESC: 0,
                DUE_ASC: 1,
                CLAIM_DESC: 2,
                CLAIM_ASC: 3,
                RECENT_ACTIVITY: 4
            }
        },
        ALERT_FLAGS: {
            NONE: 0,
            PEN_NAME_FOLLOWERS: 1,
            TEAM_FOLLOWERS: 2,
            TEAM_MEMBERS: 4
        },
        AUDIOBOOK_VENDOR: {
            NONE: 0,
            AUDIBLE: 1,
            AUTHORS_DIRECT: 2,
            KOBO: 3
        }
    },
    PEN_NAME: {
        TYPE: {
            MY_BOOK: 1,
            PUBLISHING: 2,
            TYPE_CLIENT: 3
        },
        FILTER_GROUP: {
            ALL: 0,
            FOLLOWING: 1
        }
    },
    NOTIFICATIONS: {
        UNSUB_PREFERENCE_KEYS: {
            1: 'alertNewArc',
            2: 'alertTimeToPublish',
            3: 'alertReviewDueIn2Days',
            4: 'alertReadyToReview',
            5: 'alertFinalFollowUp',
            6: 'alertDelayExpiring',
            7: 'alertDelayExpired',
            8: 'newArcParticipant',
            9: 'newReview',
            10: 'arcCancellation',
            11: 'reviewRejected',
            12: 'newExternalReview',
            13: 'freeText',
            14: 'invitedToTeam',
            15: 'arcCannotActivate',
            16: 'alertNewTeamApplicant',
            101: 'alertTimeToPublish',
            102: 'dailyUpdatesOnFollowCount',
            200: 'alertReviewDueIn2Days'
        },
        FLAGS: {
            EMAIL: 1,
            APP: 2,
            MOBILE_APP: 4
        },
        STATUS: {
            AWAITING: 1,
            COMPLETE: 2,
            CANCELLED: 3,
            FAILED: 4,
            SENT: 5,
            DELIVERED: 6,
            READ: 7,
            OPTED_OUT: 8,
            TO_BE_GENERATED: 9
        },
        STATE: {
            UNREAD: 0,
            READ: 1
        },
        NOTIFIERS: {
            NEW_ARC: {
                CODE: 'newArcAlert',
                TEMPLATE_ID: 'new-arc-alert'
            },
            TIME_TO_PUBLISH: {
                CODE: 'timeToPublish',
                TEMPLATE_ID: 'time-to-publish'
            },
            REVIEW_DUE_SOON: {
                CODE: 'reviewsDueSoon',
                TEMPLATE_ID: 'reviews-due-soon'
            },
            READY_TO_REVIEW: {
                CODE: 'readyToReview',
                TEMPLATE_ID: 'ready-to-review'
            },
            FINAL_FOLLOW_UP: {
                CODE: 'finalFollowUp',
                TEMPLATE_ID: 'final-follow-up'
            },
            DELAY_EXPIRING: {
                CODE: 'delayExpiring',
                TEMPLATE_ID: 'delay-expiring'
            },
            DELAY_EXPIRED: {
                CODE: 'delayExpired',
                TEMPLATE_ID: 'delay-expired'
            },
            NEW_ARC_PARTICIPANT: {
                CODE: 'newArcParticipant',
                TEMPLATE_ID: 'new-arc-participant'
            },
            NEW_REVIEW: {
                CODE: 'newReview',
                TEMPLATE_ID: 'new-review'
            },
            ARC_CANCELLATION: {
                CODE: 'arcCancellation',
                TEMPLATE_ID: 'arc-cancellation'
            },
            REVIEW_REJECTED: {
                CODE: 'reviewRejected',
                TEMPLATE_ID: 'review-rejected'
            },
            NEW_EXTERNAL_REVIEW: {
                CODE: 'newExternalReview',
                TEMPLATE_ID: 'new-external-review'
            },
            FREE_TEXT: {
                CODE: 'freeText',
                TEMPLATE_ID: 'free-text-notifier'
            },
            INVITED_TO_TEAM: {
                CODE: 'invitedToTeam',
                TEMPLATE_ID: 'invited-to-team'
            },
            ARC_CANNOT_ACTIVATE: {
                CODE: 'arcCannotActivate',
                TEMPLATE_ID: 'arc-cannot-activate'
            },
            NEW_TEAM_APPLICANT: {
                CODE: 'newTeamApplicant',
                TEMPLATE_ID: 'new-team-applicant'
            }
        },
        AUTHOR_NOTIFIERS: [
            'timeToPublish',
            'newArcParticipant',
            'reviewRejected',
            'newReview',
            'newExternalReview',
            'arcCancellation',
            'arcCannotActivate',
            'newTeamApplicant'
        ]
    },
    MESSAGES: {
        STATE: {
            NEW: 0,
            READ: 1,
            BLOCKED: 2,
            DELETED: 3,
            FLAGGED: 4
        }
    },
    BOOKS: {
        AUDIENCE_TYPE: {
            GENERAL: 1,
            TEEN: 2,
            MATURE: 3,
            EXPLICIT: 4
        },
        AUDIENCE_TYPE_KEYS: {
            1: 'general',
            2: 'teen',
            3: 'mature',
            4: 'explicit'
        },
        WORD_COUNT: {
            SHORT: 1,
            NOVELLA: 2,
            NOVEL: 3
        },
        SOURCE: {
            BS: 0,
            IMPORTED: 1
        },
        FILTER: {
            ALPHA: 0,
            DATE_CREATED: 1,
            LAST_ARC: 2,
            REVIEWS_ASC: 3,
            REVIEWS_DESC: 4
        },
        AWAITING_BOOK_FILES: {
            NOT_SET: 0,
            NOT_ALLOWED: 1,
            ALLOW_EPUB: 2,
            ALLOW_EPUB_PREVIEW: 4,
            ALLOW_PDF: 8,
            ALLOW_ALL: 14
        }
    },
    AUTH: {
        LOGIN_TYPE: {
            DEFAULT: 0,
            FACEBOOK: 1,
            AMAZON: 2,
            APPLE: 3
        }
    },
    SUBSCRIPTIONS: {
        DISCOUNTS: {
            END_DATE: new Date(2022, 2, 22),
            MONTH: 25,
            YEAR: 35
        },
        PLANS: {
            FREE: {
                PRICES: {
                    MONTH: 0,
                    YEAR: 0
                },
                KEY: authorRole.FREE
            },
            GROWING: {
                PRICES: {
                    MONTH: 9,
                    YEAR: 90
                },
                KEY: authorRole.GROWING
            },
            PRO: {
                PRICES: {
                    MONTH: 19,
                    YEAR: 190
                },
                KEY: authorRole.PRO
            },
            BEST_SELLER: {
                PRICES: {
                    MONTH: 29,
                    YEAR: 290
                },
                KEY: authorRole.BEST_SELLING
            },
            PUBLISHER: {
                PRICES: {
                    MONTH: 59,
                    YEAR: 590
                },
                KEY: authorRole.PUBLISHER
            }
        },
        CANCELLATION_TYPE: {
            TEMPORARY: 0,
            DIFFICULT_FOR_READERS: 1,
            DIFFICULT_FOR_ME: 2,
            NOT_WORKING: 3,
            LACKING_SUPPORT: 4,
            COST: 5,
            OTHER: 6
        },
        CANCELLATION_TYPE_KEYS: {
            0: 'temporary',
            1: 'difficultForReaders',
            2: 'difficultForMe',
            3: 'notWorking',
            4: 'lackingSupport',
            5: 'cost',
            6: 'other'
        }
    }
};
